import React from "react";
import Banner from "../../components/global/Banner";
import BarSpecialOffer from "../../components/global/BarSpecialOffer";
import OurServices from "../../components/global/OurServices";
import OurFeatures from "../../components/global/OurFeatures";
import RecentProjects from "../../components/global/RecentProjects";
import GetInTouch from "../../components/global/forms/GetInTouch";
import Testimonials from "../../components/global/Testimonials";
import PlaceholderContentBlocks from "../../components/global/product/PlaceholderContentBlocks";
import {LocationProvider} from "@reach/router";

const HomePagePreview = props => {
  const { entry } = props;
  const data = entry?.getIn(["data"])?.toJS();

  if (data) {
    return (
      <>
        <Banner {...data.banner} />
        <BarSpecialOffer {...data.barSpecialOffer} />
        <OurServices {...data.ourServices} />
        <LocationProvider>
            <PlaceholderContentBlocks {...data.placeholderContentBlocks} isPreviewMode={true} />
        </LocationProvider>
        <OurFeatures {...data.ourFeatures} />
        <RecentProjects {...data.recentProjects} />
        <Testimonials {...data.testimonials} />
        <GetInTouch {...data.getInTouch} />
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default HomePagePreview;
