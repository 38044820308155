import { useLocation } from "@reach/router";

export default function useCountryAndLocation() {
  const location = useLocation();

  // First token after the root / when splitting by /
  let firstPathToken = location.pathname.split("/")?.[1]?.toLocaleLowerCase();
  let language = location.pathname.split("/")?.[2]?.toLocaleLowerCase();
  let country = firstPathToken;

  let tenant;

  //when going to investor, push to global-en. Refactor this stakeholders have
  // a decision for header/footer pages for all global pages and we do a bigger cleanup
  switch (firstPathToken) {
    case "investors":
      language = 'en';
      tenant = null;
      country = "global";
      break;
    case "au":
      tenant = "platypus";
      language = "en";
      break;
    case "nl":
      tenant = "neushoorn";
      language = "nl";
      break;
    case "uk":
      tenant = "stoat";
      language = "en";
      break;
    case "ch":
    default:
      tenant = "chamois";
      break;
  }

  return { country, language, tenant };
}

export function useLocale() {
  const { country, language } = useCountryAndLocation();
  const locale = `${country}-${language}`;
  return locale;
}
